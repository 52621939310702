import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import MenuMobile from "./MenuMobile"
import { FaBars } from "react-icons/fa"
import PropTypes from "prop-types"

const Header = ({ isHome }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `)

  return (
    <div className="container pt-6 pb-12 md:pt-12 ">
      <div className="flex justify-between items-center">
        <div className="flex justify-between items-center">
        <Link to="/">
          <img alt="Logo" className={isHome ? "w-32 md:w-40" : "w-16 md:w-20"} src={isHome ? "/logo-white.png" : "/logo.png"} />
        </Link>
        {!isHome && <h2 className="mx-2 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-4xl sm:leading-none md:text-5xl">
            Ruald Strydom
          </h2>
        }</div>
        <button
          className="sm:hidden"
          onClick={() => setIsMenuOpen(true)}
          aria-label="Open Menu"
        >
          <FaBars className="h-6 w-auto text-gray-900 fill-current -mt-1" />
        </button>

        <div className="hidden sm:block">
          {site.data.menu.map((link, key) => (
            <Link
              key={`menu_desktop_link${key}`}
              className={`ml-6 sm:ml-8 text-sm sm:text-base font-medium px-px border-b-2 pb-2 border-transparent text-gray-${isHome ? "100" : "700"} hover:text-gray-${isHome ? "100" : "800"} hover:border-gray-200 transition duration-150 ease-in-out`}
              activeClassName={`border-green-600 text-gray-${isHome ? "200" : "900"} hover:border-green-600`}
              to={link.to}
            >
              {link.name}
            </Link>
          ))}
        </div>
      </div>
      <MenuMobile
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        links={site.data.menu}
      />
    </div>
  )
}

Header.propTypes = {
  isHome: PropTypes.bool,
}

export default Header
