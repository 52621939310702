import PropTypes from "prop-types"
import React from "react"
import "typeface-inter"
import "../styles/style.css"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Img from "gatsby-image"

const Layout = ({ children, isHome, fluid }) => {
  return (
    <div className="relative">
      {fluid && <div className="absolute w-full">
        <Img
          style={{ minHeight: "900px", top: "-250px" }}
          className="absolute object-cover w-full"
          fluid={fluid}
          alt=""
        />
      </div>}

      <div className="relative">
        <Header isHome={isHome} />
        {children}
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
}

export default Layout
