import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FaFacebook, FaInstagram, FaTwitter, FaPinterest, FaLinkedin, FaYoutube } from "react-icons/fa"
import FaHPass from "../images/hpass.svg"
import { GenIcon } from 'react-icons/lib';

const Footer = () => {
  const {
    site: {
      meta: { links },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        meta: siteMetadata {
          links {
            hpass
            twitter
            linkedin
            instagram
            youtube
          }
        }
      }
    }
  `)

  return (
    <div className="container py-12 md:flex md:items-center md:justify-between">
      <ul className="flex justify-center md:order-2">
        <li className="inline-block pl-6">
          <a
            href={links.hpass}
            target="_blank"
            rel="noreferrer noopener"
            className="text-gray-500 hover:text-blue-600 transition duration-150 ease-in-out"
          >
            <>
              <span className="sr-only">HPass</span>
              <img className="w-5 h-5" src={"/hpass.svg"} />
            </>
          </a>
        </li>
        <FooterLink href={links.twitter} icon={FaTwitter} label="Twitter" />
        <FooterLink href={links.linkedin} icon={FaLinkedin} label="LinkedIn" />
        <FooterLink href={links.instagram} icon={FaInstagram} label="Instagram" />
        <FooterLink href={links.youtube} icon={FaYoutube} label="Youtube" />
      </ul>
      <div className="mt-8 md:mt-0 md:order-1">
        <p className="text-center text-sm md:text-base text-gray-700">
          &copy; {new Date().getFullYear().toString()} Ruald Strydom. All rights reserved.
        </p>
      </div>
    </div>
  )
}

const FooterLink = ({ href, label, icon: Icon, image }) => {
  return (
    <li className="inline-block pl-6">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="text-gray-500 hover:text-blue-600 transition duration-150 ease-in-out"
      >
        <>
          <span className="sr-only">{label}</span>
          {Icon ? <Icon className="w-5 h-5 fill-current" /> : <img src={image} />}
        </>
      </a>
    </li>
  )
}

export default Footer
